import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{attrs:{"color":"transparent","flat":""}},[_c(VCardTitle,[_vm._v(" Dashboards ")]),_c(VRow,[_vm._l((_vm.dashboards),function(dashboard){return [_vm._l((dashboard.children),function(child){return [(_vm.$auth.hasPermission(child.gate))?_c(VCol,{key:child.gate,attrs:{"cols":"12","md":"2"}},[_c(VCard,{staticClass:"lighten-4",attrs:{"color":dashboard.color,"to":child.route}},[_c(VCardText,{staticStyle:{"min-height":"120px"}},[_c(VCard,{staticClass:"d-flex align-center justify-center",attrs:{"color":"transparent","flat":""}},[_c(VCard,{attrs:{"color":"transparent","flat":""}},[_c(VCardText,{staticClass:"text-center pb-0 pt-1"},[_c(VAvatar,{attrs:{"x-large":""}},[_c(VIcon,{attrs:{"color":dashboard.color,"large":""}},[_vm._v(" "+_vm._s(child.icon)+" ")])],1)],1),_c(VCardText,{staticClass:"subtitle-2 black--text text-center py-0"},[_vm._v(" "+_vm._s(child.title)+" ")])],1)],1)],1),_c(VCardActions,{class:dashboard.color},[_c(VSpacer),_c('div',{staticClass:"subtitle-2 white--text"},[_vm._v(_vm._s(dashboard.title))]),_c(VSpacer)],1)],1)],1):_vm._e()]})]})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }